import React from 'react'
import MainLayout from '../../layouts/MainLayout'
import Box from '@mui/material/Box'
import scaleLogo from '../../assets/scale_logo.png'
import Typography from '@mui/material/Typography'
import {RouteButton} from 'components/routebutton'
import Footer from 'layouts/footer'
import LandingBar from 'layouts/LandingBar'

export default function Landing(): React.ReactElement {
  return (
    <MainLayout header={<LandingBar />} footer={<Footer />}>
      <Box display={'flex'} justifyItems={'center'} justifyContent={'center'}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            mt: 15,
          }}
        >
          <img
            src={scaleLogo}
            alt='Scale logo'
            style={{
              display: 'flex',
              width: '350px',
              height: 'auto',
            }}
          />
        </Box>
      </Box>
      <Box display={'flex'} justifyItems={'center'} justifyContent={'center'} m={4}>
        <Typography
          variant='h4'
          noWrap
          component='a'
          href='/'
          sx={{
            p: '1em',
            ml: '1em',
            display: {xs: 'flex'},

            letterSpacing: '.5rem',
            color: 'inherit',
            textDecoration: 'none',
          }}
        >
          Scalable Asymmetric Lifecycle Engagement (SCALE)
        </Typography>
      </Box>
      <Box display={'flex'} justifyContent={'center'}>
        <RouteButton btntext='Login' route={'login'} fullWidth size={'large'} />
      </Box>
    </MainLayout>
  )
}
