import {useCallback, useState} from 'react'
import {apiHost} from 'config/apiConfig'
import {postData, PostDataOptions, PostDataResponse} from 'hooks/api/usePostDataFetch'
import {useAuthProvider} from 'auth/AuthProvider'
type signUpResponse = {
  token: string
}

export default function useOnActionPost<T>(): [
  (path: string, body: any, options?: PostDataOptions<T>) => Promise<void>,
  PostDataResponse<T>,
] {
  const [data, setData] = useState<T | undefined>(undefined)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<Error | undefined>()
  const {getAuthHeaders} = useAuthProvider()

  const exec = useCallback(async (path: string, body: any, options?: PostDataOptions<T>) => {
    const route = apiHost + path
    setLoading(true)
    postData<T>(route, body, {
      headers: options?.headerArgs,
      authHeaders: options?.excludeAuthHeaders === true ? undefined : getAuthHeaders(),
    })
      .then((response: T) => {
        return response
      })
      .then((response) => {
        setData(response)
        setLoading(false)
        options?.onComplete?.(response)
      })
      .catch((e) => {
        setError(e)
        setLoading(false)
        options?.onError?.(e)
      })
  }, [])

  return [exec, {data, loading, error, setData}]
}
