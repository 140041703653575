import {jwtDecode} from 'jwt-decode'
import {useState} from 'react'
import toastr from 'toastr'
import useOnActionPost from '../hooks/common/useOnActionPost'

export const useAuth = () => {
  const [execLogin, {error: errorLogin, loading: loadingLogin, data: dataLogin}] = useOnActionPost<{
    token: string
  }>()

  const [token, setTokenInternal] = useState<any>(() => {
    return localStorage.getItem('token')
  })

  const getPayloadFromToken = (token: any) => {
    if (token) {
      const decoded = jwtDecode(token)
      console.log('DECODED:' + decoded)
      //const encodedPayload = token.split('.')[1]
      return decoded
    }
    return token
  }

  const [user, setUser] = useState(() => {
    if (!token) return null
    return getPayloadFromToken(token)
  })

  const setToken = (newToken?: string) => {
    if (newToken) {
      localStorage.setItem('token', newToken)
      setTokenInternal(newToken)
      setUser(getPayloadFromToken(newToken))
    } else {
      localStorage.removeItem('token')
      setTokenInternal(undefined)
      setUser(undefined)
      //this should send them back to login or home based on routes with no user
      window.location.reload()
    }
  }

  const loginAuth = async (googleToken?: string) => {
    let retval: {token: string} | undefined
    if (googleToken) {
      console.log('google token, google auth call:' + googleToken)
      setToken(googleToken)
      return true
    }
  }

  const logoutAuth = () => {
    setToken()
  }

  return [user, loginAuth, logoutAuth, setToken]
}
