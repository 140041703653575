type HeadersInit = [string, string][] | Record<string, string> | Headers

export const defaultHeaders: HeadersInit = {
  accept: 'application/json',
  mode: 'cors',
  'content-type': 'application/json',
  'User-Agent': 'Mozilla/5.0',
}

export interface AuthHeaders {
  sessionId?: string
  Authorization?: string
}
