import MuiButton, {ButtonProps} from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import {createTheme, ThemeProvider} from '@mui/material/styles'
import React from 'react'
import {useNavigate} from 'react-router-dom'

const theme = createTheme({
  palette: {
    primary: {
      main: '#083866',
      light: '#ebf4fd',
      dark: '#232323',
      contrastText: '#FFF',
    },
  },
})
declare module '@mui/material/styles' {
  interface Palette {
    primary: Palette['primary']
  }

  // allow configuration using `createTheme`
  interface PaletteOptions {
    primary?: PaletteOptions['primary']
  }
}

// Update the Button's color prop options
declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    primary: true
  }
}

type ButtonOptions = {
  btntext: string
  route: string
}

export const RouteButton = <C extends React.ElementType>(
  props: ButtonProps<C, {component?: C}> & ButtonOptions
) => {
  const navigate = useNavigate()
  const routeChange = () => {
    const path = props.route
    navigate('/' + path)
  }
  return (
    <ThemeProvider theme={theme}>
      <Stack direction='row' spacing={2}>
        <MuiButton
          variant={props.variant ? props.variant : 'contained'}
          color={props.color ? props.color : 'primary'}
          onClick={routeChange}
          {...props}
          fullWidth
        >
          {' '}
          {props.btntext}
        </MuiButton>
      </Stack>
    </ThemeProvider>
  )
}
