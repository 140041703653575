import toastr from 'toastr'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import React, {useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {GoogleLogin, googleLogout} from '@react-oauth/google'
import {grey} from '@mui/material/colors'
import axios from 'axios'
import {useAuthProvider} from 'auth/AuthProvider'
import {useAuth} from 'auth/useAuth'
import LinearProgress from '@mui/material/LinearProgress'
import {iGoogleUser} from 'interfaces/iGoogleUser'
import {apiHost} from 'config/apiConfig'
import useOnActionGet from 'hooks/common/useOnActionGet'

export default function LoginForm(): React.ReactElement {
  const userAgent = navigator.userAgent
  const [, loginAuth] = useAuth()
  const [loadingAuth, setLoadingAuth] = useState<boolean>(false)
  const {currentUser, setCurrentUser} = useAuthProvider()
  const navigate = useNavigate()

  const [exec, {data, error, loading}] = useOnActionGet<string>()

  const callTestRoute = async () => {
    exec(`/api/test/test`, {
      onComplete: (res) => alert('called the test route'),
      onError: (error: any) => {
        toastr.error('failed test route')
      },
      excludeAuthHeaders: false,
    })
  }

  const handleMouseDownConfirmPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
  }

  const responseSuccessGoogle = (response: any) => {
    setLoadingAuth(true)
    axios({
      method: 'POST',
      url: `${apiHost}/api/auth/googlelogin`,
      data: {tokenId: response.credential},
    })
      .then(async (apiResponse: any) => {
        //now set front end auth
        console.log(apiResponse)
        console.log(apiResponse.data)
        console.log(apiResponse.data.token)
        await loginAuth(apiResponse.data.token)
        setLoadingAuth(false)
        navigate('/')
        window.location.reload()
      })
      .catch((err) => {
        console.log(err)
        setLoadingAuth(false)
      })
  }

  const responseErrorGoogle = (response: any) => {
    console.log(response)
  }

  const googleLogOut = () => {
    googleLogout()
    setCurrentUser(null)
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexGrow: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#083866',
      }}
    >
      {loadingAuth ? (
        <Box display={'flex'} flexDirection={'column'}>
          <Typography color={grey[50]} variant={'h4'}>
            Logging in....
          </Typography>
          <LinearProgress variant={'indeterminate'} />
        </Box>
      ) : (
        <Box
          style={{
            width: '400px',
            backgroundColor: '#eeeeee',
            paddingLeft: '1em',
            paddingRight: '2em',
            paddingTop: '2em',
            paddingBottom: '1em',
            borderRadius: '4px',
          }}
        >
          <Box display={'flex'} justifyContent={'center'}>
            {currentUser ? (
              <div>
                <img src={currentUser.picture} alt='user image' />
                <h3>User is already Logged in</h3>
                <p>Name: {currentUser.name}</p>
                <p>Email Address: {currentUser.email}</p>
                <br />
                <br />
                <button onClick={googleLogOut}>Log out of google</button>
              </div>
            ) : (
              //@ts-ignore
              <GoogleLogin
                onSuccess={responseSuccessGoogle}
                //@ts-ignore
                onError={responseErrorGoogle}
                cookiePolicy={'single_host_origin'}
              />
            )}
          </Box>
          <Box display={'flex'} justifyContent={'space-between'}>
            <Typography variant={'h6'}></Typography>
            <Button onClick={() => navigate('/')}>
              <Typography variant={'body2'}>Cancel</Typography>
            </Button>
          </Box>
        </Box>
      )}
      {/* <Button onClick={() => callTestRoute()}>Try Test Route</Button> */}
    </Box>
  )
}
