import React from 'react'
import axios from 'axios'
import {apiHost} from 'config/apiConfig'
import toastr from 'toastr'
import Box from '@mui/material/Box'
import UploadedFiles from './UploadedFiles'
import {grey} from '@mui/material/colors'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import {useAuth} from 'auth/useAuth'

export default function Upload() {
  //putting a fetch to get the data for the files here and passing to the component UploadedFiles so we can update it when a file is uploaded here
  const [, , logoutAuth] = useAuth()

  const responseGet = () => {
    axios({
      method: 'GET',
      url: `${apiHost}/api/data/files`,
    })
      .then(async (apiResponse: any) => {
        setData(apiResponse.data)
      })
      .catch((err) => {
        if (err.message.includes('401')) {
          logoutAuth()
        }
        return []
      })
  }

  const [data, setData] = React.useState<any>(responseGet)

  const [selectedFile, setSelectedFile] = React.useState<any>(null)

  const handleFileChange = (event: any) => {
    setSelectedFile(event.target.files[0])
  }

  const handleSubmit = async (event: any) => {
    event.preventDefault()

    const formData = new FormData()
    formData.append('file', selectedFile)

    try {
      const response = await axios.post(`${apiHost}/api/files/upload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      if (response && response.status === 200) {
        //rerun the fetch to show new file
        setSelectedFile(null)
        responseGet()
      }
      console.log('File uploaded successfully:', response.data)
    } catch (error: any) {
      toastr.error(error)
      console.error('Error uploading file:', error)
    }
  }

  //for the selected file to be cleared, I memoized this, but still if same file is selected, I think the clear is not working
  const uploadFileMemo = React.useMemo(() => {
    return (
      <Box display={'flex'} alignItems={'center'} bgcolor={grey[100]} p={1}>
        <label
          htmlFor='file-upload'
          style={{
            border: '1px solid #ccc',
            display: 'inline-block',
            padding: '6px 12px',
            cursor: 'pointer',
            backgroundColor: 'white',
          }}
        >
          Choose File To Upload
          <input
            id='file-upload'
            type='file'
            accept={'.csv'}
            onChange={handleFileChange}
            style={{display: 'none'}}
          />
        </label>
        {!selectedFile && (
          <Box display={'flex'} gap={1} ml={2}>
            <Typography variant={'body2'} color={grey[800]}>
              CSV only please, format:
            </Typography>
            <Box display={'flex'} flexDirection={'column'} bgcolor={'white'} pl={1} pr={1}>
              <Typography variant={'caption'} color={grey[600]}>
                First,Middle,Last,Email,LinkedIn
              </Typography>
              <Typography variant={'caption'} color={grey[600]}>
                Marcus,Anthony,Miller,mark@thirdcoastfederal.com,https://www.linkedin.com/in/softwaremark/{' '}
              </Typography>
            </Box>
          </Box>
        )}
        {/* <input id='file-upload' type='file' style={{display: 'none'}} /> */}
        {selectedFile ? (
          <Typography fontWeight={700} color={grey[700]} sx={{ml: 2, mr: 2}}>
            {selectedFile.name}
          </Typography>
        ) : (
          <></>
        )}
        {selectedFile && (
          <Button
            variant={'outlined'}
            size='small'
            onClick={() => setSelectedFile(null)}
            sx={{
              borderRadius: '0px',
              boxShadow: 'none',
              ml: 2,
              mr: 2,
              color: '#000000',
              border: '1px solid black',
            }}
          >
            Clear
          </Button>
        )}
        {selectedFile && (
          <Button
            variant={'contained'}
            size='small'
            type='submit'
            sx={{borderRadius: '0px', boxShadow: 'none', ml: 2, mr: 2}}
          >
            Upload
          </Button>
        )}
      </Box>
    )
  }, [selectedFile, setSelectedFile])

  return (
    <Box>
      <form onSubmit={handleSubmit}>{uploadFileMemo}</form>

      {data && <UploadedFiles data={data} callback={responseGet} />}
    </Box>
  )
}
