import {Dispatch, SetStateAction, useCallback, useState} from 'react'
import {AuthHeaders, defaultHeaders} from './headers'
import {useAuthProvider} from 'auth/AuthProvider'

export interface PostDataResponse<TData = unknown> {
  loading: boolean
  error: Error | undefined
  data: TData | undefined
  setData: Dispatch<SetStateAction<TData | undefined>>
}
//figure out how these work
export interface PostDataOptions<TResponse = unknown> {
  onComplete?: (res: TResponse) => void
  onError?: (e: any) => void
  excludeAuthHeaders?: boolean
  loading?: boolean
  headerArgs?: Record<string, string>
}

async function parseApiResponse<T = unknown>(response: Response): Promise<undefined | T | string> {
  const contentType = response.headers.get('content-type')
  if (contentType && contentType.indexOf('json') >= 0) {
    const res = await response.json()
    return res as T
  }
  return response.text()
}

export async function postData<TResponse = unknown, TBody extends BodyInit | undefined = BodyInit>(
  path: string,
  body: TBody,
  {
    headers: headerArgs,
    authHeaders,
    signal,
  }: {
    headers?: HeadersInit
    authHeaders?: AuthHeaders
    signal?: AbortSignal
  }
): Promise<TResponse> {
  const headers = new Headers({
    ...defaultHeaders,
    ...authHeaders,
    ...headerArgs,
  })
  const res = await fetch(path, {
    method: 'POST',
    headers: headers,
    credentials: 'omit',
    signal,
    body,
  })
  return parseApiResponse(res) as unknown as TResponse
}
// headers: {
//   accept: 'application/json',
//   'User-Agent': 'Mozilla/5.0',
//   'content-type': 'application/json',
// },

export default function usePostData<
  TResponse = unknown,
  TBody extends BodyInit | undefined = BodyInit,
>(
  path: string,
  headerArgs?: Record<string, string>
): [
  (body: TBody | undefined, options?: PostDataOptions<TResponse>) => Promise<void>,
  PostDataResponse<TResponse>,
] {
  const [data, setData] = useState<TResponse | undefined>(undefined)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<Error | undefined>()
  const {getAuthHeaders} = useAuthProvider()

  const execPostData = useCallback(
    async (body: TBody | undefined, options?: PostDataOptions<TResponse>) => {
      setLoading(true)
      try {
        postData(path, body, {
          headers: headerArgs,
          authHeaders: options?.excludeAuthHeaders === true ? undefined : getAuthHeaders(),
        })
          .then((response) => {
            setLoading(false)
            setData(response as TResponse)
            options?.onComplete?.(response as TResponse)
          })
          .catch((e) => {
            setData(undefined)
            setLoading(false)
            setError(e)
            options?.onError?.(e)
          })
      } catch (e) {
        setError(e as Error)
        setLoading(false)
        options?.onError?.(e)
      }
    },
    [headerArgs, path]
  )

  return [
    execPostData,
    {
      data,
      loading,
      error,
      setData,
    },
  ]
}
