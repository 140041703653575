import {Dispatch, SetStateAction, useCallback, useState} from 'react'
import {apiHost} from '../../config/apiConfig'
import {AuthHeaders, defaultHeaders} from './headers'
import {useAuthProvider} from 'auth/AuthProvider'

export interface GetDataResponse<T = unknown> {
  loading: boolean
  error: Error | undefined
  data: T | undefined
  setData: Dispatch<SetStateAction<T | undefined>>
}

export interface GetDataOptions<TResponse = unknown> {
  onComplete?: (res: TResponse) => void
  onError?: (e: any) => void
  excludeAuthHeaders?: boolean
  loading?: boolean
  headerArgs?: Record<string, string>
}

async function parseApiResponse<T = unknown>(response: Response): Promise<undefined | T | string> {
  const contentType = response.headers.get('content-type')
  if (contentType && contentType.indexOf('json') >= 0) {
    const res = await response.json()
    return res as T
  }
  return response.text()
}

export async function getData<TResponse = unknown>(
  path: string,
  {
    headers: headerArgs,
    authHeaders,
    signal,
  }: {
    headers?: HeadersInit
    authHeaders?: AuthHeaders
    signal?: AbortSignal
  }
): Promise<TResponse> {
  const headers = new Headers({
    ...defaultHeaders,
    ...authHeaders,
    ...headerArgs,
  })

  const res = await fetch(path, {
    method: 'GET',
    headers: headers,
    credentials: 'omit',
    signal,
  })
  if (res.status < 200 || res.status > 200) {
    throw new Error('Somehing went wrong...Mark M')
  }
  const result = (await parseApiResponse(res)) as unknown as TResponse
  return result
}

export default function useGetData<TResponse = unknown>(
  path: string,
  options: GetDataOptions<TResponse>,
  headerArgs?: Record<string, string>
) {
  const [data, setData] = useState<TResponse | undefined>(undefined)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<Error | undefined>()
  const {getAuthHeaders} = useAuthProvider()

  const execGetData = useCallback(
    async (options?: GetDataOptions<TResponse>) => {
      try {
        getData(path, {
          headers: headerArgs,
          authHeaders: options?.excludeAuthHeaders === true ? undefined : getAuthHeaders(),
        })
          .then((response) => {
            setLoading(false)
            setData(response as TResponse)
            options?.onComplete?.(response as TResponse)
          })
          .catch((e) => {
            setData(undefined)
            setLoading(false)
            setError(e)
            options?.onError?.(e)
          })
      } catch (e) {
        setError(e as Error)
        setLoading(false)
        options?.onError?.(e)
      }
    },
    [headerArgs, path]
  )

  return [
    execGetData,
    {
      data,
      loading,
      error,
      setData,
    },
  ]
}
